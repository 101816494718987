import {GetStaticProps} from 'next';
import {inject} from 'mobx-react';
import {
  DatoPage,
  getStaticProps as customPageGetStaticProps,
} from './[...path]';

export default inject('authStore', 'checkoutStore')(DatoPage);

export const getStaticProps: GetStaticProps = (ctx) => {
  return customPageGetStaticProps({...ctx, params: {path: ['404']}});
};

import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';

export const getClientSideRedirectPath = (path: string): string | null => {
  const pathWithoutLocale = path.replace(`/en/`, '/');

  if (path.includes('/Login/ResetPassword')) {
    return path.replace(/.*\/ResetPassword/, '/resetPassword');
  }
  if (path.indexOf('termsandconditions') > -1) {
    return '/legal/terms';
  }
  if (path.indexOf('Login') > -1) {
    return path.replace(`Login`, 'login');
  }
  if (path.toLowerCase().indexOf('deliveries') > -1) {
    return '/profile/deliveries?public';
  }
  if (path.toLowerCase().indexOf('invoices') > -1) {
    return '/profile/invoices?public';
  }
  if (pathWithoutLocale !== path) {
    return pathWithoutLocale;
  }
  return null;
};

/**
 * This replaces the `getInitialProps` function for /pages/[...path].js and /pages/[language]/[...path].js
 */
export const useLegacyCatchAll = (hasLoadableDataForRoute = false): boolean => {
  const [isRedirecting, setIsRedirecting] = useState(hasLoadableDataForRoute);
  const router = useRouter();

  const pathWithoutLocale = router.asPath.replace(
    `/${router.query.language || 'en'}/`,
    '/',
  );

  useEffect(() => {
    const redirectPath = getClientSideRedirectPath(router.asPath);

    if (redirectPath) router.replace(redirectPath);

    setIsRedirecting(!!redirectPath);
  }, [pathWithoutLocale, router]);

  return isRedirecting;
};

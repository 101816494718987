import React, {useEffect} from 'react';
import {GetStaticPaths, GetStaticProps} from 'next';
import {
  APOLLO_STATE_PROP_NAME,
  initializeApollo,
} from 'ui/components/runway-east/DatoApolloProvider';
import {
  AuthStore,
  CheckoutStore,
  getStaticPropsForAppWrapper,
} from 'ui/components/withAppWrapper';
import {DatoPageContent} from 'ui/components/runway-east/page/DatoPageContent';
import {
  getClientSideRedirectPath,
  useLegacyCatchAll,
} from 'hooks/use-legacy-catch-all';
import {
  getPathsFromQuery,
  PageQuery,
  fetchPageQuery,
} from 'hooks/use-page-query';
import {
  DatoPagePathsDocument,
  DatoPagePathsQuery,
} from 'graphql/generated/graphql';
import {convertRedirectsCsvToNextRedirects} from 'redirects';
import {inject} from 'mobx-react';
import {useRouter} from 'next/router';

interface PageProps {
  pageQuery: PageQuery | null;
  authStore: AuthStore;
  checkoutStore: CheckoutStore;
  [APOLLO_STATE_PROP_NAME]: Record<string, unknown>;
}

export const DatoPage: React.FC<PageProps> = ({pageQuery, ...mobxStore}) => {
  const isRedirecting = useLegacyCatchAll(!pageQuery);
  const router = useRouter();

  useEffect(() => {
    if (!isRedirecting && !pageQuery) {
      router.push('/404');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageQuery, isRedirecting]);

  if (isRedirecting) return <div style={{padding: 20}}>Redirecting...</div>;
  if (!pageQuery) return null;

  return (
    <div>
      <DatoPageContent {...mobxStore} pageQuery={pageQuery} />
    </div>
  );
};

type PageParams = {path: string[]};

export const getStaticPaths: GetStaticPaths<PageParams> = async () => {
  // `getStaticPaths` is not preview aware. If a path is drafted in DatoCMS, then `getStaticProps` will handle it
  const apolloClient = initializeApollo(null, false);

  const {data} = await apolloClient.query<DatoPagePathsQuery>({
    query: DatoPagePathsDocument,
  });

  const pathInParams = getPathsFromQuery(data).map((path) => ({
    params: {path},
  }));

  return {
    paths: pathInParams,
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps<
  PageProps,
  PageParams
> = getStaticPropsForAppWrapper<PageProps, PageParams>(
  undefined,
  async (context, apolloClient) => {
    if (!context.params) return {props: {}};

    const {path: pathSegments} = context.params;

    const path = pathSegments.join('/');
    const pathWithPrefixedForwardSlash = `/${path}`;

    const pageQuery = await fetchPageQuery(apolloClient, {
      path: pathWithPrefixedForwardSlash,
    });

    if (!pageQuery) {
      const nextJsRedirectConfigList = (await convertRedirectsCsvToNextRedirects()) as {
        source: string;
        destination: string;
        permanent: boolean;
      }[];

      const [redirect] = nextJsRedirectConfigList.filter(
        ({source}) => source === pathWithPrefixedForwardSlash,
      );

      if (redirect) {
        return {
          redirect,
        };
      }

      const clientSideRedirectPath = getClientSideRedirectPath(path);

      if (!clientSideRedirectPath) {
        return {
          notFound: true,
        };
      }
    }

    return {
      revalidate: 60,
      props: {
        pageQuery,
      },
    };
  },
);

export default inject('authStore', 'checkoutStore')(DatoPage);
